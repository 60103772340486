import React, { Component } from 'react';
import {Container, Row, Col, Button } from "react-bootstrap";
import errorImage from '../../images/error_image.svg';

class PageNotFoundComponent extends Component {
    render() {
        return (
            <Container className="h-100 page-not-found">
                <Row className="h-100 justify-content-center align-items-center">
                    <Col lg={7}>
                        <img src={errorImage} className="float-lg-right" alt="error" />
                    </Col>
                    <Col lg={5}>
                        <div className="ml-4">
                            <h3 className="text-quill">Page Not Found</h3>
                            <p className="m-0 text-quill-lightest">We can’t find the page you are looking for. <br />You can return to previous page.</p>
                            <Button variant="primary" className="mt-3 w-50" onClick={() => { window.history.back(); }}>Go to Previous Page</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default PageNotFoundComponent;
