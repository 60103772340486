import axios from "axios";
import UrlConstants from "../../utils/UrlConstants";
import { DefaultConfig } from "./default-config";

let configMap: any = DefaultConfig;
const BaseUrl = process.env.REACT_APP_BASE_URL;
const MpaasConfigService = {

    // Getting Configuratins from server.
    getConfigurations: (): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .get(BaseUrl + UrlConstants.MPS_ADMIN_PANEL_CONFIG_API_URL)
                .then((data: any) => {
                    // This is to update the local response from server response.
                    for (let key in data.data) {
                        configMap[key] = data.data[key];
                    }
                    ///
                    resolve(configMap);
                })
                .catch((error) => {
                    console.error("failed to fetch Configurations: ", error);
                    resolve(configMap);
                });
        });
    },

    getConfigMap: () => {
        return configMap;
    }
}



export { MpaasConfigService };