import React from "react";

const ToggleSwitch = (props: any) => {
    const { isEnabled, onToggle } = props;

    return (
        <div className={`position-relative toggle-switch ${isEnabled ? 'enabled' : 'disabled'}`} onClick={onToggle}>
            <div className='position-absolute toggle-knob bg-white'></div>
        </div>
    )
}

export default ToggleSwitch;