const UrlConstants = {
    CLIENT_DETAILS: "/clientDetails",
    MPS_KEYS: "/keys/mps",
    LTI_KEYS: "/keys/lti",
    UPDATE_MPAAS_FLAG: "/client/newMpaasFlag",
    GET_PROCTORING_SETTINGS: "/client/proctoringSettings",
    ACCOUNT_USAGE_SUMMARY: "/accountUsageSummary",
    ZOHO_ACCOUNT_DETAILS: "/zohoAccountDetails",
    SUB_USER: "/subuser",
    DETAILED_ACCOUNT_USAGE_SUMMARY: "/detailedAccountUsageSummary",
    UPDATE_PROCTORING_SETTINGS: "/updateClient",
    UPDATE_CLIENT_STATUS: "/client/block",
    DOWNLOAD_ACCOUNT_USAGE_SUMMARY: "/download/accountUsageSummary",
    DOWNLOAD_DETAILED_ACCOUNT_USAGE_SUMMARY: "/download/detailedAccountUsageSummary",
    ALL_ACCOUNT_USAGE_SUMMARY: "/allAccountUsageSummary",
    RE_GENERATE_API_KEYS: "/keys/mps/regenerate",
    RE_GENERATE_LTI_KEYS: "/keys/lti/regenerate",
    LOGIN_KEY: "/supportLoginKey",
    MPS_ADMIN_PANEL_CONFIG_API_URL: "/appConfig",
    AUDIOINFO:"/audioProctoringDiagnosticInfo",
    GROUP_PROCTORING_STATISTICS : "/groupProctoringStatistics",
    CANDIDATE_PROCTORING_STATISTICS : "/candidateProctoringStatistics",
    DOWNLOAD_GROUP_PROCTORING_STATISTICS : "/download/groupProctoringStatistics",
    DOWNLOAD_CANDIDATE_PROCTORING_STATISTICS : "/download/candidateProctoringStatistics",
    PROHIBITED_SOFTWARES: "/prohibited/software",
    CLIENT_THEME_SETTING: "/v1/client",
    TEAM_HIERARCHY_DETAILS: "/getTeam"
}

export default UrlConstants;