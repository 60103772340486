import React, { useState, useRef, useEffect } from 'react';
import { Card, Button, Row, Col, InputGroup, FormControl, Modal } from 'react-bootstrap';
import { AiOutlineHome } from "react-icons/ai";

import Validator from "../../utils/Validator";
import Logger from "../../utils/Logger";
import { mpaasHttpAction } from "../../utils/MpaasHttpActionHandler";
import MpaasAdminPanelApi from '../../utils/MpaasAdminPanelApi';
import dataStorage from '../../dataStorage/dataStorage';

import { MpsKeysDataModels, LtiKeysDataModels } from "../../models/DataModels";

const MpaasLtiKeyHandlingComponent: React.FC = () => {
    const [emailid, setEmailid] = useState('');
    const [emailInvalidErrorMsg, setEmailInvalidErrorMsg] = useState('');
    const [fetchMpaasKeysStatusView, setFetchMpaasKeysStatusView] = useState(<></>);
    const [isMpaasKeysDataAvailable, setIsMpaasKeysDataAvailable] = useState<boolean>(false);
    const [apiKeysData, setApiKeysData] = useState<MpsKeysDataModels | null>(null);
    const [ltiKeysData, setLtiKeysData] = useState<LtiKeysDataModels | null>(null);
    const [modalParams, setModalParams] = useState({} as any);

    const privateApiKeyRef = useRef<any>();
    const publicApiKeyRef = useRef<any>();
    const privateLtiKeyRef = useRef<any>();
    const publicLtiKeyRef = useRef<any>();

    const _prepareAndDisplayMpaasKeysData = () => {
        setApiKeysData(dataStorage.getMpsKeysData());
        setLtiKeysData(dataStorage.getLtiKeysData());
        setFetchMpaasKeysStatusView(<></>);
        setIsMpaasKeysDataAvailable(true);
    }

    const _checkAndDisplayMpaasKeys = () => {
        if (!dataStorage.getMpsKeysData() || !dataStorage.getLtiKeysData()) {
            Logger.info("Fetching mpaas keys data in progress.", null);
            return;
        }

        _prepareAndDisplayMpaasKeysData();
    }

    const _resetData = () => {
        dataStorage.setMpsKeysData(null);
        dataStorage.setLtiKeysData(null);
    }

    const _updateApiEnableDisableStatus = () => {
        let apiSuccessMsg = apiKeysData?.enabled ? 'Keys has been successfully disabled' : 'Keys has been successfully enabled';
        let apiErrorMsg = apiKeysData?.enabled ? 'Error while disabling keys' : 'Error while enabling keys';

        MpaasAdminPanelApi.updateApiEnableDisableStatus({ emailId: emailid, enabled: !apiKeysData?.enabled }).then((response: any) => {
            setModalParams({ ...modalParams, actions: ['Close'], apiResponse: <span className="text-success">{apiSuccessMsg}</span> });
            let data = dataStorage.getMpsKeysData();
            if (data) {
                data.enabled = response.enabled;
            }

            dataStorage.setMpsKeysData(data);
            setApiKeysData(dataStorage.getMpsKeysData());
        }, (error) => {
            mpaasHttpAction.error(error);
            setModalParams({ ...modalParams, actions: ['Close'], apiResponse: <span className="text-danger">{apiErrorMsg}</span> });
        });
    }

    const _updateLtiEnableDisableStatus = () => {
        let apiSuccessMsg = ltiKeysData?.enabled ? 'Keys has been successfully disabled' : 'Keys has been successfully enabled';
        let apiErrorMsg = ltiKeysData?.enabled ? 'Error while disabling keys' : 'Error while enabling keys';

        MpaasAdminPanelApi.updateLtiEnableDisableStatus({ emailId: emailid, enabled: !ltiKeysData?.enabled }).then((response: any) => {
            setModalParams({ ...modalParams, actions: ['Close'], apiResponse: <span className="text-success">{apiSuccessMsg}</span> });
            let data = dataStorage.getLtiKeysData();
            if (data) {
                data.enabled = response.enabled;
            }

            dataStorage.setLtiKeysData(data);
            setLtiKeysData(dataStorage.getLtiKeysData());
        }, (error) => {
            mpaasHttpAction.error(error);
            setModalParams({ ...modalParams, actions: ['Close'], apiResponse: <span className="text-danger">{apiErrorMsg}</span> });
        });
    }

    const _reGenerateApiKeys = () => {
        MpaasAdminPanelApi.reGenerateApiKeys({ emailId: emailid }).then((response: any) => {
            setModalParams({ ...modalParams, actions: ['Close'], apiResponse: <span className="text-success">Keys has been successfully re-generated</span> });
            let data = dataStorage.getMpsKeysData();
            if (data) {
                data.publicKey = response.publicKey;
                data.privateKey = response.privateKey;
                data.enabled = response.enabled;
            }
            dataStorage.setMpsKeysData(data);
            setApiKeysData(dataStorage.getMpsKeysData());
        }, (error) => {
            mpaasHttpAction.error(error);
            setModalParams({ ...modalParams, actions: ['Close'], apiResponse: <span className="text-danger">Error while re-generating keys</span> });
        });
    }

    const _generateApiKeys = () => {
        MpaasAdminPanelApi.generateApiKeys({ emailId: emailid }).then((response: any) => {
            setModalParams({ ...modalParams, actions: ['Close'], apiResponse: <span className="text-success">Keys has been successfully generated</span> });
            let data = dataStorage.getMpsKeysData();
            if (data) {
                data.publicKey = response.publicKey;
                data.privateKey = response.privateKey;
                data.enabled = response.enabled;
                data.mpaasClientId = response.mpaasClientId;
            }
            dataStorage.setMpsKeysData(data);
            setApiKeysData(dataStorage.getMpsKeysData());
        }, (error) => {
            mpaasHttpAction.error(error);
            setModalParams({ ...modalParams, actions: ['Close'], apiResponse: <span className="text-danger">Error while generating keys</span> });
        });
    }

    const _reGenerateLtiKeys = () => {
        MpaasAdminPanelApi.reGenerateLtiKeys({ emailId: emailid }).then((response: any) => {
            setModalParams({ ...modalParams, actions: ['Close'], apiResponse: <span className="text-success">Keys has been successfully re-generated</span> });
            let data = dataStorage.getLtiKeysData();
            if (data) {
                data.publicKey = response.publicKey;
                data.privateKey = response.privateKey;
                data.enabled = response.enabled;
            }
            dataStorage.setLtiKeysData(data);
            setLtiKeysData(dataStorage.getLtiKeysData());
        }, (error) => {
            mpaasHttpAction.error(error);
            setModalParams({ ...modalParams, actions: ['Close'], apiResponse: <span className="text-danger">Error while re-generating keys</span> });
        });
    }

    const _generateLtiKeys = () => {
        MpaasAdminPanelApi.generateLtiKeys({ emailId: emailid }).then((response: any) => {
            setModalParams({ ...modalParams, actions: ['Close'], apiResponse: <span className="text-success">Keys has been successfully generated</span> });
            let data = dataStorage.getLtiKeysData();
            if (data) {
                data.publicKey = response.publicKey;
                data.privateKey = response.privateKey;
                data.enabled = response.enabled;
            }
            dataStorage.setLtiKeysData(data);
            setLtiKeysData(dataStorage.getLtiKeysData());
        }, (error) => {
            mpaasHttpAction.error(error);
            setModalParams({ ...modalParams, actions: ['Close'], apiResponse: <span className="text-danger">Error while generating keys</span> });
        });
    }

    const saveMpsKeysAction = (action: string) => {
        setModalParams({ ...modalParams, actions: null, apiResponse: <span>Saving...</span> });
        switch (action) {
            case 'apiEnable':
                _updateApiEnableDisableStatus();
                break;
            case 'apiGenerate':
                if (apiKeysData?.keysGenerated) {
                    _reGenerateApiKeys();
                } else {
                    _generateApiKeys();
                }
                break;
            case 'ltiEnable':
                _updateLtiEnableDisableStatus();
                break;
            case 'ltiGenerate':
                if (ltiKeysData?.keysGenerated) {
                    _reGenerateLtiKeys();
                } else {
                    _generateLtiKeys();
                }
                break;
            default:
                Logger.info("Invalid save mps keys action.", action);
        }
    }

    const showModal = (keysAction: string) => {
        let msg: string = '';
        let modalParams: object = {};
        let defaultParams = {
            "title": "Alert",
            "message": "",
            "actions": ['Close', 'Save'],
            "mpsKeysAction": keysAction,
            "apiResponse": (<></>)
        }

        switch (keysAction) {
            case 'apiEnable':
                msg = apiKeysData?.enabled ? 'Are you sure you want to disable keys ?' : 'Are you sure you want to enable keys ?';
                modalParams = { ...defaultParams, message: msg };
                break;
            case 'ltiEnable':
                msg = ltiKeysData?.enabled ? 'Are you sure you want to disable keys ?' : 'Are you sure you want to enable keys ?';
                modalParams = { ...defaultParams, message: msg };
                break;
            case 'apiGenerate':
                msg = apiKeysData?.keysGenerated ? 'Are you sure you want to re-generate keys ?' : 'Are you sure you want to generate keys ?';
                modalParams = { ...defaultParams, message: msg };
                break;
            case 'ltiGenerate':
                msg = ltiKeysData?.keysGenerated ? 'Are you sure you want to re-generate keys ?' : 'Are you sure you want to generate keys ?';
                modalParams = { ...defaultParams, message: msg };
                break;
            default:
                Logger.info("Invalid modal keys action.", keysAction);
        }

        setModalParams(modalParams);
    }

    const copyKey = (textRef: any) => {
        textRef?.current?.select();
        document.execCommand('copy');
    }

    const getClient = () => {
        setEmailInvalidErrorMsg('');
        let emailValidationData = Validator.validateEmail(emailid);
        if (!emailValidationData.isValid) {
            setEmailInvalidErrorMsg(emailValidationData.message);
            Logger.error("Incorrect client email id.", null);
            return;
        }

        _resetData();
        setFetchMpaasKeysStatusView(mpaasHttpAction.loading());
        MpaasAdminPanelApi.fetchMpsKeysData({ emailId: emailid }).then((response: any) => {
            dataStorage.setMpsKeysData(response);
            _checkAndDisplayMpaasKeys();
        }, (error) => {
            setFetchMpaasKeysStatusView(mpaasHttpAction.error(error));
        });

        MpaasAdminPanelApi.fetchLtiKeysData({ emailId: emailid }).then((response: any) => {
            dataStorage.setLtiKeysData(response);
            _checkAndDisplayMpaasKeys();
        }, (error) => {
            setFetchMpaasKeysStatusView(mpaasHttpAction.error(error));
        });
    }

    useEffect(() => {
        return () => {
            _resetData();
        }
    }, []);

    return (
        <>
            <div className="d-none">
                <AiOutlineHome /> <span className="small"> &gt; MPaaS/LTI’s Keys Handling</span>
                <br /><br />
                <h5>Generate API Key</h5>
                <p className="text-muted small">You can generate/deactive API keys available for the Client</p>

                <Row>
                    <Col lg={5}>
                        <Card className="border-left-4 shadow-sm p-3">
                            <Card.Body className="text-muted font-weight-light p-0 mb-2">Please provide an Email ID</Card.Body>
                            <Row>
                                <Col lg={9}>
                                    <input type="email" value={emailid} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setEmailid(e.target.value) }} className={"w-100 px-2 py-1 border rounded bg-sky-lighter " + (emailInvalidErrorMsg ? 'border-danger' : '')} placeholder="email@email.com" required />
                                    <div className="text-danger pt-2">{emailInvalidErrorMsg}</div>
                                </Col>
                                <Col lg={3}>
                                    <Button variant="primary" className="py-1" onClick={getClient}>Submit</Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                {fetchMpaasKeysStatusView}
                {isMpaasKeysDataAvailable &&
                    <>
                        <hr />
                        <h5>MPaaS Keys Status</h5>
                        <br />
                        <Row>
                            <Col lg={6}>
                                <h5>API Keys
                                {apiKeysData?.keysGenerated &&
                                        <Button variant="link" size="sm" className="float-right mr-2 text-danger py-0" onClick={() => { showModal("apiEnable") }}>
                                            {apiKeysData?.enabled ? 'Disable' : 'Enable'}
                                        </Button>
                                    }
                                    <Button variant="link" size="sm" className="float-right py-0" onClick={() => { showModal("apiGenerate") }}>
                                        {apiKeysData?.keysGenerated ? 'Re-Generate' : 'Generate'}
                                    </Button>
                                </h5>
                                <hr />
                                {(apiKeysData?.keysGenerated && apiKeysData?.enabled) ?
                                    <div>
                                        <p className="text-quill-lightest mb-1">Private API Key:</p>
                                        <InputGroup className="mb-3 w-75" size="sm">
                                            <FormControl ref={privateApiKeyRef} value={apiKeysData?.privateKey} readOnly />
                                            <InputGroup.Append>
                                                <Button variant="outline-primary" onClick={() => { copyKey(privateApiKeyRef) }}>Copy</Button>
                                            </InputGroup.Append>
                                        </InputGroup>
                                        <p className="text-quill-lightest mb-1">Public API Key:</p>
                                        <InputGroup className="mb-3 w-75" size="sm">
                                            <FormControl ref={publicApiKeyRef} value={apiKeysData?.publicKey} readOnly />
                                            <InputGroup.Append>
                                                <Button variant="outline-primary" onClick={() => { copyKey(publicApiKeyRef) }}>Copy</Button>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </div>
                                    :
                                    <>
                                        {!apiKeysData?.keysGenerated &&
                                            <p className="text-quill-lightest">Key is not generated, Please Generate API Key.</p>
                                        }
                                        {apiKeysData?.keysGenerated && !apiKeysData?.enabled &&
                                            <p className="text-quill-lightest">Key is disabled. Please enable the Key to Use.</p>
                                        }
                                    </>
                                }
                            </Col>
                            <Col lg={6}>
                                <h5>LTI Keys
                                {ltiKeysData?.keysGenerated &&
                                        <Button variant="link" size="sm" className="float-right mr-2 text-danger py-0" onClick={() => { showModal("ltiEnable") }}>
                                            {ltiKeysData?.enabled ? 'Disable' : 'Enable'}
                                        </Button>
                                    }
                                    <Button variant="link" size="sm" className="float-right py-0" onClick={() => { showModal("ltiGenerate") }}>
                                        {ltiKeysData?.keysGenerated ? 'Re-Generate' : 'Generate'}
                                    </Button>
                                </h5>
                                <hr />
                                {(ltiKeysData?.keysGenerated && ltiKeysData?.enabled) ?
                                    <div>
                                        <p className="text-quill-lightest mb-1">Private LTI Key:</p>
                                        <InputGroup className="mb-3 w-75" size="sm">
                                            <FormControl ref={privateLtiKeyRef} value={ltiKeysData?.privateKey} readOnly />
                                            <InputGroup.Append>
                                                <Button variant="outline-primary" onClick={() => { copyKey(privateLtiKeyRef) }}>Copy</Button>
                                            </InputGroup.Append>
                                        </InputGroup>
                                        <p className="text-quill-lightest mb-1">Public LTI Key:</p>
                                        <InputGroup className="mb-3 w-75" size="sm">
                                            <FormControl ref={publicLtiKeyRef} value={ltiKeysData?.publicKey} readOnly />
                                            <InputGroup.Append>
                                                <Button variant="outline-primary" onClick={() => { copyKey(publicLtiKeyRef) }}>Copy</Button>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </div>
                                    :
                                    <>
                                        {!ltiKeysData?.keysGenerated &&
                                            <p className="text-quill-lightest">Key is not generated, Please Generate API Key.</p>
                                        }
                                        {ltiKeysData?.keysGenerated && !ltiKeysData?.enabled &&
                                            <p className="text-quill-lightest">Key is disabled. Please enable the Key to Use.</p>
                                        }
                                    </>
                                }
                            </Col>
                        </Row>
                    </>
                }

                <Modal show={Object.keys(modalParams).length > 0} onHide={() => void 0} animation={false}>
                    <Modal.Header>
                        <Modal.Title>{modalParams.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modalParams.message}</Modal.Body>
                    <Modal.Footer>
                        {modalParams.apiResponse}
                        {modalParams.actions &&
                            <>
                                <Button variant="outline-primary" onClick={() => { setModalParams({}) }}>
                                    {modalParams.actions[0]}
                                </Button>
                                {modalParams.actions[1] &&
                                    <Button variant="primary" onClick={() => { saveMpsKeysAction(modalParams.mpsKeysAction) }}>
                                        {modalParams.actions[1]}
                                    </Button>
                                }
                            </>
                        }
                    </Modal.Footer>
                </Modal>
            </div>
            <h4 className="pt-5 text-center">
                Mpaas Lti key handling is coming soon...
            </h4>
        </>
    )
}

export default MpaasLtiKeyHandlingComponent