import React, { useState, useEffect } from 'react';
import { Table, Form, Tabs, Tab, Button, Modal } from 'react-bootstrap';
import { AiOutlineHome, AiOutlineInfoCircle } from "react-icons/ai";
import { HiArrowLeft } from "react-icons/hi";
import { FaRegEdit, FaArrowDown } from "react-icons/fa";
import loaderGif from "../../images/gif/loader.gif";

import { mpaasHttpAction, fetchDataActions } from "../../utils/MpaasHttpActionHandler";
import { ClientDetails, AccountUsageSummary, ZohoAccountDetails, SubUsers } from "../../models/DataModels";
import MpaasAdminPanelApi from '../../utils/MpaasAdminPanelApi';
import dataStorage from '../../dataStorage/dataStorage';
import Logger from "../../utils/Logger";

import DetailedAccountUsageSummary from "./DetailedAccountUsageSummary";
import { useInitDataContext } from "../../utils/InitDataContextProvider";

const AccountSummary = (props: any) => {
    const [initAppData] = useInitDataContext();
    const [fetchAccountSummaryView, setFetchAccountSummaryView] = useState(<></>);
    const [isAccountSummaryDataAvailable, setIsAccountSummaryDataAvailable] = useState(false);
    const [disableProctoringSettingsEditOption, setDisableProctoringSettingsEditOption] = useState(true);
    const [isSavingProctoringSettings, setIsSavingProctoringSettings] = useState(false);
    const [proctoringSettings, setStateProctoringSettings] = useState<any>();
    const [usageSummaryTable, setUsageSummaryTable] = useState<(AccountUsageSummary & ZohoAccountDetails)[]>([]);
    const [subUsersTable, setSubUsersTable] = useState<SubUsers[]>([]);
    const [modalParams, setModalParams] = useState({} as any);
    const [detailedAccUsageSumInitData, setDetailedAccUsageSumInitData] = useState({ isOpen: false, data: {} });
    const [showAudioCheck,setshowAudioCheck] = useState(false);

    const clientDetails: ClientDetails = props.initData.clientDetails;

    const _changeAccountSummaryView = (displayView: string, details: object) => {
        switch (displayView) {
            case fetchDataActions.LOADING:
                return setFetchAccountSummaryView(mpaasHttpAction.loading());
            case fetchDataActions.SUCCESS:
                setFetchAccountSummaryView(<></>);
                setIsAccountSummaryDataAvailable(true);
                break;
            case fetchDataActions.ERROR:
                return setFetchAccountSummaryView(mpaasHttpAction.error(details));
            default:
                Logger.warn("Invalid account summary view. Display view", displayView);
                return <></>;
        }
    }

    const _prepareAndDisplayAccountSummaryView = () => {
        setStateProctoringSettings(dataStorage.getProctoringSettings());
        let accountUsageSummaryData: AccountUsageSummary[] = dataStorage.getAccountUsageSummaryData();
        let zohoAccountDetails: ZohoAccountDetails[] = dataStorage.getZohoAccountDetails();
        setSubUsersTable(dataStorage.getSubUsers());

        let usageSummaryArr: (AccountUsageSummary & ZohoAccountDetails)[] = [];
        for (let i = 0; i < accountUsageSummaryData.length; i++) {
            for (let j = 0; j < zohoAccountDetails.length; j++) {
                if (accountUsageSummaryData[i].clientEmail === zohoAccountDetails[j].emailId) {
                    usageSummaryArr.push({ ...accountUsageSummaryData[i], ...zohoAccountDetails[j] });
                }
            }
        }

        Logger.info("Usage Summary Table:", usageSummaryArr);
        setUsageSummaryTable(usageSummaryArr);
        _changeAccountSummaryView(fetchDataActions.SUCCESS, {});
    }

    const _checkAndDisplayAccountSummaryPage = () => {
        if (!dataStorage.getProctoringSettings() || dataStorage.getAccountUsageSummaryData().length < 1 || dataStorage.getZohoAccountDetails().length < 1 || dataStorage.getSubUsers().length < 1) {
            Logger.info("Fetching account details data in progress.", null);
            return;
        }

        _prepareAndDisplayAccountSummaryView();
    }

    const _makeListRequestParam = (emailId: string) => {
        let emailIds: string[] = [];
        emailIds.push(emailId);

        return emailIds;
    }

    const _fetchAccountSummaryDataFromServer = (emailId: string, mettlClientId: number) => {
        if (!emailId || !mettlClientId) {
            let errorDetails = mpaasHttpAction.makeErrorData("Invalid argument to fetch account summary data drom server. EmailId:", emailId);
            _changeAccountSummaryView(fetchDataActions.ERROR, errorDetails);
            return;
        }

        MpaasAdminPanelApi.fetchProctoringSettings({ clientEmail: emailId }).then((response: any) => {
            dataStorage.setProctoringSettings(response);
            response.recording ? setshowAudioCheck(true) : setshowAudioCheck(false); 
            _checkAndDisplayAccountSummaryPage();
        }, (error) => {
            _changeAccountSummaryView(fetchDataActions.ERROR, error);
        });

        MpaasAdminPanelApi.fetchAccountUsageSummary({ emailIds: _makeListRequestParam(emailId) }).then((response: any) => {
            dataStorage.setAccountUsageSummaryData(response);
            _checkAndDisplayAccountSummaryPage();
        }, (error) => {
            _changeAccountSummaryView(fetchDataActions.ERROR, error);
        });

        MpaasAdminPanelApi.fetchZohoAccountDetails({ emailIds: _makeListRequestParam(emailId) }).then((response: any) => {
            dataStorage.setZohoAccountDetails(response);
            _checkAndDisplayAccountSummaryPage();
        }, (error) => {
            _changeAccountSummaryView(fetchDataActions.ERROR, error);
        });

        MpaasAdminPanelApi.fetchSubUser({ emailID: emailId, mettlClientId: mettlClientId }).then((response: any) => {
            dataStorage.setSubUsers(response);
            _checkAndDisplayAccountSummaryPage();
        }, (error) => {
            _changeAccountSummaryView(fetchDataActions.ERROR, error);
        });
    }

    const _resetAccountSummaryStoreData = () => {
        dataStorage.setProctoringSettings(null);
        dataStorage.setAccountUsageSummaryData([]);
        dataStorage.setZohoAccountDetails([]);
        dataStorage.setSubUsers([]);
    }

    const saveProctoringSettings = () => {
        if (!clientDetails.mpaasClientId) {
            Logger.error("Error while saving proctoring settings. Invalid mpaas client id:", clientDetails.mpaasClientId);
            return;
        }

        let reqParams = {
            "mpaasClientId": clientDetails.mpaasClientId,
            "proctoringSetting": {
                "authorization": proctoringSettings.authorization,
                "screenCapture": proctoringSettings.screenCapture,
                "recording": proctoringSettings.recording,
                "whitelisting_url": proctoringSettings.whiteListing,
                "audio_proctoring": proctoringSettings.audioProctoring,
                "msb": proctoringSettings.msb
            }
        }

        setIsSavingProctoringSettings(true);
        MpaasAdminPanelApi.updateProctoringSetting(reqParams).then((response: any) => {
            Logger.info("Proctoring settings successfully saved. Message:", response.Message);
            dataStorage.setProctoringSettings(proctoringSettings);
            setIsSavingProctoringSettings(false);
            setDisableProctoringSettingsEditOption(true);
            alert("Proctoring settings successfully saved.");
        }, (error) => {
            mpaasHttpAction.error(error);
            alert("Error while saving proctoring settings. Please try again later.");
            setStateProctoringSettings(dataStorage.getProctoringSettings());
            setIsSavingProctoringSettings(false);
            setDisableProctoringSettingsEditOption(true);
        });
    }

    const changeProctoringSettings = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStateProctoringSettings({ ...proctoringSettings, [e.target.name]: e.target.checked });
        if (e.target.name == "recording") {
            if (e.target.checked) {
                setshowAudioCheck(true);
            }else {
                setshowAudioCheck(false);
            }
        } 
    }

    useEffect(() => {
        if(!showAudioCheck){
            setStateProctoringSettings({ ...proctoringSettings, "audioProctoring": false });
        }
    },[showAudioCheck]);

    const openDetailedUsageSummaryView = (mPaasId: number, clientEmail: string) => {
        setDetailedAccUsageSumInitData({
            isOpen: true,
            data: {
                mpaasClientId: mPaasId,
                emailId: clientEmail
            }
        });
    }
    

    const handleDownloadError = (error: any) => {
        mpaasHttpAction.error(error);
        setModalParams({ ...modalParams, message: <span className="text-danger">{error.message}</span> });
    }

    const handleDownloadSuccess = (msg: string, resp: any) => {
        Logger.info(msg, resp);
        setModalParams({
            ...modalParams, message: (
                <>
                    <span className="d-inline-block align-top"><FaArrowDown /></span>
                    <span className="d-inline-block ml-2" style={{ width: "calc(100% - 25px)" }}>
                        <h4> Download Data</h4>
                        <p>The details requested will be sent to you on your available E-mail ID <br /><strong> {initAppData.email}</strong></p>
                        <p className="text-muted small mb-0"><AiOutlineInfoCircle /> The Download Link will be active for 24 hours only.</p>
                    </span>
                </>
            )
        });
    }

    const downloadUsageSummary = () => {
        setModalParams({
            "message": (<div className="text-center">
                <img src={loaderGif} alt="loading" height="100px" width="100px" />
            </div>)
        });

        MpaasAdminPanelApi.downloadAccountUsageSummary({ emailIds: _makeListRequestParam(clientDetails.emailId), isMonitorViewAllActivities: false }).then((response: any) => {
            handleDownloadSuccess("Successfully sent email for download account usage summary.", response);
        }, (error) => {
            handleDownloadError(error);
        });
    }

    useEffect(() => {
        _changeAccountSummaryView(fetchDataActions.LOADING, {})
        _resetAccountSummaryStoreData();
        _fetchAccountSummaryDataFromServer(clientDetails.emailId, clientDetails.mettlClientId);
        return () => {
            _resetAccountSummaryStoreData();
        }
    }, []);

    return (
        <>
            {fetchAccountSummaryView}
            {isAccountSummaryDataAvailable &&
                <div>
                    <AiOutlineHome /> <span className="small"> &gt; <span className="text-muted">View Account Details</span> &gt; Account Summary</span>
                    <br /><br />
                    <h5>
                        <HiArrowLeft className="align-bottom c-pointer" onClick={() => { props.setInitData({ isOpen: false, data: {} }) }} /> Account Summary
                        <span className={"border rounded py-1 px-2 mx-3 small " + (clientDetails.accountStatus === 'Active' ? "border-success text-success" : "border-danger text-danger")}>{clientDetails.accountStatus}</span>
                    </h5>

                    <div className="mx-4 my-3">
                        <Table borderless className="account-summary-data">
                            <tbody>
                                <tr>
                                    <td>Client Name</td>
                                    <td>{clientDetails.name}</td>
                                </tr>
                                <tr>
                                    <td>Organisation</td>
                                    <td>{clientDetails.organization}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2} className="py-2"></td>
                                </tr>
                                <tr>
                                    <td>Account Manager</td>
                                    <td>{clientDetails.accountManager}</td>
                                </tr>
                                <tr>
                                    <td>Account Type</td>
                                    <td>{clientDetails.accountType}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2} className="py-2"></td>
                                </tr>
                                <tr>
                                    <td>Last Login</td>
                                    <td>{clientDetails.lastLoginDate}</td>
                                </tr>
                                <tr>
                                    <td>Creation Date</td>
                                    <td>{clientDetails.creationDate}</td>
                                </tr>
                            </tbody>
                        </Table>

                        <h6 className="text-quill-lighter mt-4">Proctoring Settings &nbsp;
                            {disableProctoringSettingsEditOption && <FaRegEdit className="c-pointer" onClick={() => { setDisableProctoringSettingsEditOption(false) }} />}
                        </h6>
                        <Form>
                            <Form.Check inline type="checkbox" label="Authorisation" name="authorization" checked={proctoringSettings?.authorization} onChange={changeProctoringSettings} disabled={disableProctoringSettingsEditOption} />
                            <Form.Check inline type="checkbox" label="Screen Capture" name="screenCapture" className="ml-3" checked={proctoringSettings?.screenCapture} onChange={changeProctoringSettings} disabled={disableProctoringSettingsEditOption} />
                            <Form.Check inline type="checkbox" label="Full Session Recording" name="recording" className="ml-3" checked={proctoringSettings?.recording} onChange={changeProctoringSettings} disabled={disableProctoringSettingsEditOption} />
                            <Form.Check inline type="checkbox" label="Whitelisting URL's" name="whiteListing" className="ml-3" checked={proctoringSettings?.whiteListing} onChange={changeProctoringSettings} disabled={disableProctoringSettingsEditOption} />
                            {showAudioCheck ? 
                            <Form.Check inline type="checkbox" label="Audio Proctoring" name="audioProctoring" className="ml-3" checked={proctoringSettings?.audioProctoring} onChange={changeProctoringSettings} disabled={disableProctoringSettingsEditOption} />
                            :""}
                            <Form.Check inline type="checkbox" label="Mettl Secure Browser" name="msb" className="ml-3" checked={proctoringSettings?.msb} onChange={changeProctoringSettings} disabled={disableProctoringSettingsEditOption} />
                            


                            {!disableProctoringSettingsEditOption &&
                                <span className="ml-5">
                                    {isSavingProctoringSettings ? <span>Saving...</span> :
                                        <Button variant="link" className="p-0 align-baseline" onClick={saveProctoringSettings}>Save Settings</Button>
                                    }
                                </span>
                            }
                        </Form>
                    </div>

                    <hr />

                    {!detailedAccUsageSumInitData.isOpen ?
                        <>
                            <Tabs defaultActiveKey="tab1" id="uncontrolled-tab-example" transition={false} unmountOnExit={true} className="mb-4 account-summary-tab border-0">
                                <Tab eventKey="tab1" title="Usage Summary">
                                    {/* <Button variant="link" className="float-right py-0" onClick={downloadUsageSummary}><FaArrowDown /> Download All</Button> */}
                                    <p className="text-quill-lightest">Showing {usageSummaryTable.length} Results</p>
                                    <Table bordered responsive className="text-nowrap">
                                        <thead className="bg-sky-lighter">
                                            <tr>
                                                <th>Mpaas Client Id</th>
                                                <th>Email id</th>
                                                <th>Zoho Id</th>
                                                <th>Zoho Account Name</th>
                                                <th>Last Session Date</th>
                                                <th>Groups Created</th>
                                                <th>Proctoring Duration (Hrs)</th>
                                                <th>Candidate Count</th>
                                                <th>Unique Reports generated</th>
                                                <th>Avg. Proctoring Duration Per Report</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {usageSummaryTable.length > 0 && usageSummaryTable.map((value, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>
                                                            <span className="clientid-table-td">{value.mpaasClientId}</span>
                                                            <Button variant="outline-primary" size="sm" className="ml-4" onClick={() => { openDetailedUsageSummaryView(value.mpaasClientId, value.clientEmail) }} >View Details</Button>
                                                        </td>
                                                        <td>{value.clientEmail}</td>
                                                        <td>{value.zohoId}</td>
                                                        <td>{value.zohoAccountName}</td>
                                                        <td>{value.lastLoginDate}</td>
                                                        <td>{value.groupsCount}</td>
                                                        <td>{value.proctoringDuration}</td>
                                                        <td>{value.usersCount}</td>
                                                        <td>{value.usersCount}</td>
                                                        <td>{value.avgProctoringDuration}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </Tab>
                                <Tab eventKey="tab2" title="Sub Users">
                                    <p className="text-quill-lightest">Showing {subUsersTable.length} Results</p>
                                    <Table bordered responsive className="text-nowrap">
                                        <thead className="bg-sky-lighter">
                                            <tr>
                                                <th>Email ID</th>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Last Login Date</th>
                                                <th>Creation Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {subUsersTable.length > 0 && subUsersTable.map((value, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{value.emailID}</td>
                                                        <td>{value.firstName}</td>
                                                        <td>{value.lastName}</td>
                                                        <td>{value.lastLoginDate}</td>
                                                        <td>{value.creationDate}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </Tab>
                            </Tabs>
                        </>
                        :
                        <>
                            <h6>
                                <HiArrowLeft className="align-bottom c-pointer" onClick={() => { setDetailedAccUsageSumInitData({ isOpen: false, data: {} }) }} /> Back to Usage Summary
                            </h6>
                            <DetailedAccountUsageSummary initData={detailedAccUsageSumInitData} setInitData={setDetailedAccUsageSumInitData} />
                        </>
                    }
                </div>
            }

            <Modal show={Object.keys(modalParams).length > 0} onHide={() => void 0} animation={false} centered>
                <Modal.Body>{modalParams.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => { setModalParams({}) }}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AccountSummary