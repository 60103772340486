import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import welcomeImg from '../../images/welcome.svg';

const HomeComponent: React.FC = () => {
    return (
        <Container className="d-flex flex-column h-100 justify-content-center">
            <Row>
                <Col lg={5}>
                    <img src={welcomeImg} className="float-lg-right" alt="powered by mercer mettl logo" />
                </Col>
                <Col lg={7}>
                    <div className="d-flex flex-column h-100 justify-content-center">
                        <h5>Hi there! <br /> Welcome to MPaaS Dashboard</h5>
                        <p className="m-0">Please select one of the section on left panel to Get started</p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default HomeComponent;
