import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import poweredByImg from '../../images/powered_by.svg';
import { BiPhone } from "react-icons/bi";
import { GoMailRead } from "react-icons/go";

const FooterComponent: React.FC = () => {
    return (
        <footer className="footer border-top text-center default-outer-space bg-white">
            <Container fluid={true}>
                <Row>
                    <Col lg={{ span: 3 }}>
                        Mettl Online Assessment © 2010-2020
                    </Col>
                    <Col lg={{ span: 6 }}>
                        Need Technical Help? Contact on :<BiPhone /> +91-828778-03040 <span className="pl-1"><GoMailRead /> support@mettl.com</span>
                    </Col>
                    <Col lg={{ span: 3 }}>
                        Powered By: <img src={poweredByImg} className="align-text-bottom" alt="powered by mercer mettl logo" />
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default FooterComponent
