const Validator = {
    validateEmail: (emailId: string) => {
        let validationResp = {
            isValid: true,
            message: ""
        };

        if (!emailId) {
            validationResp.isValid = false;
            validationResp.message = "Please enter user email id."
        }

        if (emailId !== "") {
            var pattern = new RegExp(/^(?!.*?\.\.)([a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-])+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/);

            if (!pattern.test(emailId)) {
                validationResp.isValid = false;
                validationResp.message = "Please enter valid user email id."
            }
        }

        return validationResp;
    }
}

export default Validator;