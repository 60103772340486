import axios from "axios";
import UrlConstants from "../../../utils/UrlConstants";
import { httpDataStatus, mpaasHttp } from "../../../utils/MpaasHttpClient";
import { PLATFORM } from "./ProhibitedSoftwares.constants";
import { ProhibitedSoftwaresListModel } from "./ProhibitedSoftwares.model";


const ProhibitedSoftwaresStore = {
    fetchProhibitedSoftwares: (data: Object) => {
        return new Promise((resolve, reject) => {
            mpaasHttp.get(UrlConstants.PROHIBITED_SOFTWARES+`?platform=${data}`)
            .then((response: any) => {
                if (response.status === httpDataStatus.SUCCESS) {
                    resolve(response);
                } else {
                    reject(response);
                }
            })
            .catch((error) => {
                reject(error);
            });
        });
    },
    saveProhibitedSoftwares: (data: Object) => {
        return new Promise((resolve, reject) => {
            mpaasHttp.post(UrlConstants.PROHIBITED_SOFTWARES, data)
            .then((response: any) => {
                if (response.status === httpDataStatus.SUCCESS) {
                    resolve(response);
                } else {
                    reject(response);
                }
            })
            .catch((error) => {
                reject(error);
            });
        });
    },
    updateProhibitedSoftwares: (data: Object) => {
        return new Promise((resolve, reject) => {
            mpaasHttp.put(UrlConstants.PROHIBITED_SOFTWARES, data)
            .then((response: any) => {
                if (response.status === httpDataStatus.SUCCESS) {
                    resolve(response);
                } else {
                    reject(response);
                }
            })
            .catch((error) => {
                reject(error);
            });
        });
    },
    makeProhibitedSoftwareList: (data: any, currentPlatform: string) : ProhibitedSoftwaresListModel[] => {
        let delimiter = ProhibitedSoftwaresStore.getDelimiter(currentPlatform);
        let isMac = currentPlatform === PLATFORM.MAC;

        return data.map((softwareObj: any, index: number) => ({
            id: index,
            name: isMac ? softwareObj.split(delimiter)[0] : "",
            executable: isMac ? softwareObj.split(delimiter)[1] : softwareObj,
            isChecked: false
        }));
    },
    getDelimiter: (platform: string) => {
        return platform === PLATFORM.WINDOWS ? "" : "|";
    },
    getSoftwareNameToDisplay: (softwareObj: ProhibitedSoftwaresListModel, platform: string): string => {
        if (platform === PLATFORM.WINDOWS)
            return softwareObj.executable;

        return softwareObj.name + " " + ProhibitedSoftwaresStore.getDelimiter(platform) + " " + softwareObj.executable;
    },
    isSaveMacPayloadValid: (name: string, executable: string) => {
        if (name.length > 255 || executable.length > 255) {
            alert("Software name/executable string length can not be greater than 255");
            return false;
        }
        return true;
    }
}

export default ProhibitedSoftwaresStore;