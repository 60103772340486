import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from './AppRoutingComponent';
import './App.css';

class AppComponent extends Component {
    render() {
        return (
            <Router>
                <AppRouter />
            </Router>
        )
    }
}

export default AppComponent;
