import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { AiOutlineHome } from "react-icons/ai";
import { FaArrowLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import dataStorage from '../../dataStorage/dataStorage';
import { WhitelabellingSettingsModel } from '../../models/DataModels';
import MpaasAdminPanelApi from '../../utils/MpaasAdminPanelApi';
import ToggleSwitch from '../toggleSwitch/ToggleSwitch';
import './globalSettings.css';

const MiscellaneousSettings: React.FC = () => {
    const history = useHistory();

    const [whitelabellingSettings, setWhitelabellingSettings] = useState <WhitelabellingSettingsModel>({
        allowClientTheme: false,
        allowBranding: false,
        allowSupportContact: false
    })

    const saveClientThemeSetting = () => {
        const payload = {
            ALLOW_CLIENT_THEME: whitelabellingSettings.allowClientTheme,
            SHOW_BRANDING_IN_FOOTER: whitelabellingSettings.allowBranding,
            SHOW_SUPPORT_CONTACT_IN_FOOTER: whitelabellingSettings.allowSupportContact
        }

        const clientId = dataStorage.getClientDetailsForWhiteLabelling()[0]?.mpaasClientId;

        MpaasAdminPanelApi.saveClientThemeSetting({ clientId, payload }).then((data: any) => {
            console.log('Saved client theme settings.');
            alert('Saved client theme settings');
        }).catch((err) => {
            console.log('Failed to save client theme settings', err);
            alert('Failed to save client theme settings');
        })
    }

    const fetchClientThemeSetting = () => {
        const clientId = dataStorage.getClientDetailsForWhiteLabelling()[0]?.mpaasClientId;

        if(!clientId){
            history.push('/settings/accountDetails')
            return;
        }

        MpaasAdminPanelApi.fetchClientThemeSetting({ clientId }).then((data: any) => {

            setWhitelabellingSettings({
                allowClientTheme: data?.clientSettings?.ALLOW_CLIENT_THEME,
                allowBranding: data?.clientSettings?.SHOW_BRANDING_IN_FOOTER,
                allowSupportContact: data?.clientSettings?.SHOW_SUPPORT_CONTACT_IN_FOOTER
            })

        }).catch((err) => {
            history.push('/settings/accountDetails')
            alert('Failed to Fetch client theme settings');
        })
    }

    const toggleWhiteLabellingSetting = (updatedSetting: any) => {
        setWhitelabellingSettings((data: WhitelabellingSettingsModel) => {
            return {...data, ...updatedSetting}
        })
    }

    useEffect(() => {
        fetchClientThemeSetting();
    }, [])

    return (
        <div>
            <div className='breadcrumb-section'>
                <AiOutlineHome /> <span className="small"> &gt; <span className="text-muted">Settings</span> &gt; <span className="text-muted">Account Details</span> &gt; <span className="text-muted">Miscellaneous Settings</span></span>
                <div className='d-flex mt-3 ml-2'>
                    <FaArrowLeft className='mr-3 mt-1 cursor-pointer' onClick={() => history.push('/settings/accountDetails')} />
                    <span className=''>
                        <span className='font-weight-bold h5 text-quill-lighter'>Miscellaneous Settings</span>
                        <div className='font-weight-light text-quill-lighter mt-1'>You can configure other available settings for Windows & Mac</div>
                    </span>
                </div>

                <div className='setting-container mt-5 mx-3 pr-5'>
                    <div className='w-100 client-theme d-flex justify-content-between'>
                        <div>
                            <div className='font-weight-bold h6 text-quill-lighter'>Allow Client Theme</div>
                            <div className='font-weight-light'>When enabled, Client can configure SecureProctor theme.</div>
                        </div>
                        <div>
                            <ToggleSwitch isEnabled={whitelabellingSettings.allowClientTheme} onToggle={() => toggleWhiteLabellingSetting({allowClientTheme: !whitelabellingSettings.allowClientTheme})} />
                        </div>
                    </div>
                    <div className='mt-4 d-flex justify-content-center align-items-center w-100'><div className='branding'>Configure Mercer | Mettl Branding</div> <hr className='w-100'></hr></div>
                    <div className='mt-4 w-100 client-theme d-flex justify-content-between'>
                        <div>
                            <div className='font-weight-bold h6 text-quill-lighter'>Show <img src='./mercer-mettl.svg' /> branding in SecureProctor's Footer</div>
                            <div className='font-weight-light'>When enabled, Mettl branding will be visible in SecureProctor's footer</div>
                        </div>
                        <div>
                            <ToggleSwitch isEnabled={whitelabellingSettings.allowBranding} onToggle={() => toggleWhiteLabellingSetting({allowBranding: !whitelabellingSettings.allowBranding})} />
                        </div>
                    </div>
                    <div className='mt-4 w-100 client-theme d-flex justify-content-between'>
                        <div>
                            <div className='font-weight-bold h6 text-quill-lighter'>Show support contact information in SecureProctor's Footer</div>
                            <div className='font-weight-light'>When enabled, Mettl support information will be visible in SecureProctor's footer</div>
                        </div>
                        <div>
                            <ToggleSwitch isEnabled={whitelabellingSettings.allowSupportContact} onToggle={() => toggleWhiteLabellingSetting({allowSupportContact: !whitelabellingSettings.allowSupportContact})} />
                        </div>
                    </div>
                </div>
                <div className='w-100 mt-5'>
                    <Button variant='primary' className='ml-3 p-2' onClick={saveClientThemeSetting}>Save Changes</Button>
                </div>
            </div>
        </div>
    )
}

export default MiscellaneousSettings;