import React from 'react';
import { Nav, Navbar, DropdownButton, Dropdown, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";

import { useInitDataContext } from "../../utils/InitDataContextProvider";

const HeaderComponent: React.FC = () => {
    const [initAppData] = useInitDataContext();
    let initialsChar = initAppData.firstName.charAt(0) + initAppData.lastName.split(" ").pop().charAt(0);

    const logout = () => {
        localStorage.removeItem("userDetails");
        window.location.href="/admin-api/logout";
    }

    return (
        <>
            <Navbar bg="dark" variant="dark" fixed="top" className="default-outer-space">
                <Link className="navbar-brand" to="/home">
                    <img src={process.env.PUBLIC_URL + "/mercer_mettl_logo_white.svg"} alt="mercer mettl logo" />
                </Link>
                <Nav className="ml-auto align-items-center position-relative mr-3">
                    <DropdownButton alignRight title={initialsChar} id="dropdown-menu-align-right" className="user-header-dropdown">
                        <Dropdown.ItemText className="text-nowrap">
                            <span className="d-inline-block text-white rounded-circle text-uppercase name-circle">{initialsChar}</span>
                            <small className="d-inline-block">{initAppData.firstName +" "+ initAppData.lastName}<br /> <span className="text-muted">{initAppData.email}</span></small>
                        </Dropdown.ItemText>
                        <Dropdown.ItemText>
                            <Dropdown.Divider className="my-1" />
                        </Dropdown.ItemText>
                        <Dropdown.ItemText>
                            <Button variant="primary" className="w-100" onClick={logout}>Logout</Button>
                        </Dropdown.ItemText>
                    </DropdownButton>
                </Nav>
            </Navbar>
        </>
    )
}

export default HeaderComponent
