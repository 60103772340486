import React, { Component } from 'react'

class ViewCandidateDetailsComponent extends Component {
    render() {
        return (
            <h4 className="pt-5 text-center">
                View Candidate Details is coming soon...
            </h4>
        )
    }
}

export default ViewCandidateDetailsComponent
