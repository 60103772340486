import React, { createContext, useContext, useState } from "react";
import { initDataPacketStore } from "../dataStorage/initDataStore";

export const InitDataContext = createContext<any>([initDataPacketStore.getInitAppData(), () => { }]);

export const useInitDataContext = () => useContext(InitDataContext);

export const InitDataContextProvider: React.FC = (props: any) => {
    const [initData, setInitData] = useState(initDataPacketStore.getInitAppData());
    const defaultInitDataContext: any = [initData, setInitData];

    return (
        <InitDataContext.Provider value={defaultInitDataContext}>
            {props.children}
        </InitDataContext.Provider>
    )
}