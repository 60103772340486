import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import loaderGif from "../../images/gif/loader.gif";

import { mpaasHttp, httpDataStatus } from "../../utils/MpaasHttpClient";
import { useInitDataContext } from "../../utils/InitDataContextProvider";
import Logger from "../../utils/Logger";

const LoginComponent: React.FC = () => {
    const [initAppData, setInitAppData] = useInitDataContext();
    const [isAppLoading, setIsAppLoading] = useState(true);
    const userDetails = localStorage.getItem("userDetails");
    const history = useHistory();

    const _handleGetUserSuccess = (userDetails: Object) => {
        Logger.info("Get user data success.", userDetails);
        localStorage.setItem("userDetails", JSON.stringify(userDetails));
        setInitAppData(userDetails);
        setIsAppLoading(false);
        history.push('/home');
    }

    const _handleGetUserError = (error: any) => {
        Logger.info("Error while fetching user data. Reason: ", error);
        setIsAppLoading(false);
    }

    useEffect(() => {
        if (!userDetails) {
            setIsAppLoading(true);
            mpaasHttp.get("/user", null).then((resp: any) => {
                if (resp.status === httpDataStatus.SUCCESS && resp.userDetails) {
                    _handleGetUserSuccess(resp.userDetails);
                } else {
                    _handleGetUserError(resp);
                }
            }, (error) => {
                _handleGetUserError(error);
            });
        } else {
            setIsAppLoading(false);
            history.push('/home');
        }
    }, []);
    return (
        <>
            {isAppLoading ?
                <>
                    <div className="text-center mt-5 pt-5">
                        <img src={loaderGif} alt="loading" height="150px" width="150px" />
                    </div>
                </>
                :
                <Container>
                    <Row className="justify-content-md-center my-5 mx-0">
                        <Col lg={{ span: 6 }}>
                            <Card className="shadow-sm">
                                <Card.Header className="bg-white border-bottom-0">
                                    <Card.Img className="d-block mx-auto" style={{ width: "192px", height: "auto" }} src={process.env.PUBLIC_URL + "/mercer_mettl_logo.svg"} alt="mercer mettl logo" />
                                </Card.Header>
                                <Card.Body className="text-center mt-4">
                                    <h5>Sign-In to Continue</h5>
                                    <Card.Text>
                                        Click on Sign-In button to proceed with Okta enabled Verification
                                    <br /><br />
                                        <Button variant="primary" onClick={() => { window.location.href = "/admin-api/OAuthLogin"; }}>Sign-in</Button>
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer className="bg-white px-4">
                                    <h6>Rules</h6>
                                    <ol className="px-3 m-0">
                                        <li>Please use only Mettl E-mail ID.</li>
                                        <li>Please logout from other Okta accounts.</li>
                                    </ol>
                                </Card.Footer>
                                <div className="border-bottom-4"></div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            }
        </>
    )
}

export default LoginComponent
