import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { AiOutlineHome } from "react-icons/ai";
import { FaArrowLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import Logger from '../../utils/Logger';
import MpaasAdminPanelApi from '../../utils/MpaasAdminPanelApi';
import Validator from '../../utils/Validator';
import './globalSettings.css';
import dataStorage from '../../dataStorage/dataStorage';
import { fetchDataActions, mpaasHttpAction } from '../../utils/MpaasHttpActionHandler';

const AccountDetailsForWhiteLabelling: React.FC = () => {
    const [userEmailId, setUserEmailId] = useState('');
    const [emailInvalidErrorMsg, setEmailInvalidErrorMsg] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [accountDetailesTableView, setAccountDetailesTableView] = useState(<></>);
    const history = useHistory();

    const _changeAccountDetailsTableView = (displayView: string, details: object) => {
        switch (displayView) {
            case fetchDataActions.LOADING:
                return setAccountDetailesTableView(mpaasHttpAction.loading());
            case fetchDataActions.ERROR:
                return setAccountDetailesTableView(mpaasHttpAction.error(details));
            default:
                Logger.warn("Invalid account details table view. Display view", displayView);
                return <></>;
        }
    }

    const _fetchAccountDetailsDataFromServer = () => {
        _changeAccountDetailsTableView(fetchDataActions.LOADING, {});
        setIsButtonDisabled(true);

        MpaasAdminPanelApi.fetchClientDetails({ emailId: userEmailId }).then((response: any) => {
            dataStorage.setClientDetailsForWhiteLabelling(response.clientDetails);
            history.push('/settings/accountDetails/miscellaneousSettings')
        }, (error) => {
            setIsButtonDisabled(false);
            _changeAccountDetailsTableView(fetchDataActions.ERROR, error)
        });
    }

    const searchAccountDetails = () => {
        setEmailInvalidErrorMsg('');
        let emailValidationData = Validator.validateEmail(userEmailId);
        if (!emailValidationData.isValid) {
            setEmailInvalidErrorMsg(emailValidationData.message);
            Logger.error("Incorrect user email id.", null);
            return;
        }

        _fetchAccountDetailsDataFromServer();
    }

    return (
        <div>
            <AiOutlineHome /> <span className="small"> &gt; View Account Details</span>
            <br /><br />
            <h5>View Account Details</h5>
            <p className="text-muted small">You can view account details based on Users Email ID</p>

            <Row>
                <Col lg={5}>
                    <Card className="border-left-4 shadow-sm p-3">
                        <Card.Body className="text-muted font-weight-light p-0 mb-2">Please provide an Email ID</Card.Body>
                        <Row>
                            <Col lg={9}>
                                <input type="email" value={userEmailId} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setUserEmailId(e.target.value) }} className={"w-100 px-2 py-1 border rounded bg-sky-lighter " + (emailInvalidErrorMsg ? 'border-danger' : '')} placeholder="email@email.com" required />
                                <div className="text-danger pt-2">{emailInvalidErrorMsg}</div>
                            </Col>
                            <Col lg={3}>
                                <Button variant="primary" className="py-1" disabled={isButtonDisabled} onClick={searchAccountDetails}>Search</Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            {accountDetailesTableView}
        </div>
    )
}

export default AccountDetailsForWhiteLabelling;