import React, { useState, useEffect } from 'react';

import { Table, Form, Button} from 'react-bootstrap';
import { AiOutlineHome,AiOutlineArrowLeft,AiOutlineDoubleLeft,AiOutlineDoubleRight } from "react-icons/ai";
import MpaasAdminPanelApi from '../../utils/MpaasAdminPanelApi';
import Logger from "../../utils/Logger";
import loaderGif from "../../images/gif/loader.gif";
 
const AudioInfoComponent: React.FC = () => {
    const [pid, setPid] = useState("");
    const [gid, setGid] = useState(""); 
    const [gidValue, setGidValue] = useState(""); 
    const [gidFlow,setGidFlow] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [errorMsg,bindError] = useState("");
    const [errorToast,setToast] = useState(false);
    const [tableView,setTableView] = useState(false);
    const [pageNext,setPageNext] = useState(false);
    const [pagePrevious,setPagePrevious] = useState(false);
    const [showLoader, setshowLoader] = useState(false);
    const [formView,setFormView] = useState(true);
    const [noData,setNoData] = useState(false);
    const [audioData,setAudioData] = useState({} as any);
    const [pidPayload,setpidPayload] = useState({
        firstPid:"",
        lastPid:"",
    } as any);
    const [datePayload,setdatePayload] = useState({
        savedStartDate:"",
        savedEndDate:"",
    } as any);
    const maxDate = formatDate(new Date());
    const [acitivePaginationIndex,setacitivePaginationIndex] = useState(1);

    function formatDate(date:any) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const _getaudioDataPid = (pid:any) => {
        if(pid==""){
            bindError("ProctoringInstance Id cannot be empty");
            _toastbar();
            return;
       }     
       setPid('');
       setshowLoader(true);
       setTableView(false);
        setFormView(false);
        MpaasAdminPanelApi.getAudioDatawithPid({ pid: pid }).then((response: any) => {
            setshowLoader(false);
            if(response.status=="ERROR"){
                bindError(response.error.message);
                _toastbar();
                setFormView(true);
                
            } else{
                setAudioResponseData(response);
            }
        }, (error) => {
            Logger.error("error",error);
             });
    }


    const _getaudioDataGid = (gid:any) => {
        if(gid==""){
            bindError("Group Id cannot be empty");
            _toastbar();
            return;
       }
        let payload = {
            "fpid":0,
            "gid":gid,
            "page": "NEXT",
            "pNo":1
        }
        setGidValue(payload.gid);
        setGid('');
        setGidFlow(true);
        setshowLoader(true);
        setTableView(false);
        setFormView(false);
        MpaasAdminPanelApi.getAudioDataWithGid(payload).then((response: any) => {
            setshowLoader(false);
            if(response.status=="ERROR"){
                bindError(response.error.message);
                _toastbar();
                setFormView(true);
            } else{
                setAudioResponseData(response);
            }
        }, (error) => {
            Logger.error("error",error);
             });
    }



    const _getaudioDatabyDate = (startDate:any,endDate:any) => {
        if(startDate == "" || endDate == ""){
            bindError("Dates can't be empty");
            _toastbar();
        } else{
            var start = new Date(startDate);
            var end = new Date(endDate);

            if(start > end){
                bindError("invalid interval selected");
                _toastbar();
                return;
            }
            let payload = {
                fpid:0,
                startDate:new Date(startDate).setHours(0,0,0,0),
                endDate:new Date(endDate).setHours(23,59,59,0),
                page: "NEXT",
                pNo:1
            }
            setGidFlow(false);
            setdatePayload({
                savedStartDate:payload.startDate,
                savedEndDate:payload.endDate,
            })
            setStartDate('');
            setEndDate('');
            setshowLoader(true);
            setTableView(false);
            setFormView(false);
            MpaasAdminPanelApi.getAudioDatawithDate(payload).then((response: any) => {
                setshowLoader(false);
                if(response.status=="ERROR"){
                    bindError(response.error.message);
                    _toastbar();
                    setFormView(true);
                } else{
                    setAudioResponseData(response);
                }
            }, (error) => {
                Logger.error("error",error);
                 });
        }

    }

    const _NextAudioData = (PageNo:any) => {
        var payload:any;
        if(gidFlow) {
             payload = {
                fpid:pidPayload.lastPid,
                gid:gidValue,
                page: "NEXT",
                pNo:PageNo+1
            }
            _getAudioByGid(payload);
        } else {
             payload = {
                fpid:pidPayload.lastPid,
                startDate: datePayload.savedStartDate,
                endDate: datePayload.savedEndDate,
                page: "NEXT",
                pNo:PageNo+1
            }
            _getAudioByDates(payload);
        }
        setshowLoader(true);
        setTableView(false);
        setFormView(false);

    }

    const _PreviousAudioData = (PageNo:any) => {
        var payload:any;
        if(PageNo==2){
            pidPayload.lastPid = 0;
        }
        if(gidFlow) {
             payload = {
                fpid:pidPayload.firstPid,
                gid:gidValue,
                page: "PREVIOUS",
                pNo:PageNo-1
            }
            _getAudioByGid(payload);
        } else {
            payload = {
                fpid:pidPayload.firstPid,
                startDate: datePayload.savedStartDate,
                endDate: datePayload.savedEndDate,
                page: "PREVIOUS",
                pNo:PageNo-1
            }
            _getAudioByDates(payload);
        }
        setshowLoader(true);
        setTableView(false);
        setFormView(false);
    }

   const _toastbar=()=>{
        setToast(true);
        setTimeout(function(){
            setToast(false);
        },4000);
    }
    const _getAudioByGid = (payload:any) => {
        MpaasAdminPanelApi.getAudioDataWithGid(payload).then((response: any) => {
            setshowLoader(false);
            if(response.status=="ERROR"){
                bindError(response.error.message);
                _toastbar();
                setFormView(true);
            } else{
                setacitivePaginationIndex(payload.pNo);
                setAudioResponseData(response);
            }
            console.log(response);
        }, (error) => {
            Logger.error("error",error);
             });
    }

    const _getAudioByDates = (payload:any) => {
        MpaasAdminPanelApi.getAudioDatawithDate(payload).then((response: any) => {
            setshowLoader(false);
            if(response.status=="ERROR"){
                bindError(response.error.message);
                _toastbar();
                setFormView(true);
            } else{
                setacitivePaginationIndex(payload.pNo);
                setAudioResponseData(response);
            }
        }, (error) => {
            Logger.error("error",error);
        });
    }

    const setAudioResponseData = (response:any) => {
            if(response.audioProctoringDiagnosticInfo.length==0){
                setAudioData(response.audioProctoringDiagnosticInfo);
                setTableView(true);
                setFormView(false);
                setNoData(true);
                return;
            }
            setAudioData(response.audioProctoringDiagnosticInfo);
            setPageNext(response.pageMetaData.hasNext);
            setPagePrevious(response.pageMetaData.hasPrevious);
                let fpidData=response.audioProctoringDiagnosticInfo;
                setpidPayload({
                    firstPid:fpidData[0].pid,
                    lastPid:fpidData[fpidData.length - 1].pid,
                });
            setTableView(true);
            setFormView(false);
            console.log(response);
    }

    const goBack = () => {
        setTableView(false);
        setFormView(true);
        setacitivePaginationIndex(1);
        setshowLoader(false);
        setNoData(false);
    }

    useEffect(() => {
    }, []);
    return (
        <>
        <div className='breadcrumb-section'>
        <AiOutlineHome /> <span className="small"> &gt; <span className="text-muted">Audio Proctoring Info</span> </span>
        </div>
        <div className='audio-info-section'>
        <h4 className="title">View Audio Proctoring Diagnostic Instances</h4>
        {
            errorToast ?
            <div className='toast-bar'>
                    <span>{errorMsg}</span>
            </div> : ''
        }
        {
            formView ? 
            <><div className='candidate-form'>
                            <Form>
                                <Form.Group className="mb-3" controlId="formPid">
                                    <Form.Label>Proctroing Instance Id</Form.Label>
                                    <Form.Control type="number" value={pid} placeholder="Enter Proctroing Instance Id" onChange={(e) => setPid(e.target.value)} />
                                </Form.Group>
                                <Button variant="primary" type="button" onClick={() => _getaudioDataPid(pid)}>
                                    Diagonstic Info
                                </Button>
                            </Form>
                            <hr></hr>
                        </div><div className='assessment-form'>
                                <Form>
                                    <Form.Group className="mb-3" controlId="formGid">
                                        <Form.Label>Group Id</Form.Label>
                                        <Form.Control type="number" value={gid} placeholder="Enter Group Id" onChange={(e) => setGid(e.target.value)} />
                                    </Form.Group>
                                    <Button variant="primary" type="button" onClick={() => _getaudioDataGid(gid)}>
                                        Diagonstic Info
                                    </Button>
                                </Form>
                                <hr></hr>
                            </div><div className='range-form'>
                                <h4>Date Range</h4>
                                <Form>
                                    <Form.Group className="mb-3" controlId="formSd">
                                        <Form.Label>Start Date</Form.Label>
                                        <Form.Control type="date" value={startDate} max={maxDate} onChange={(e) => setStartDate(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formEd">
                                        <Form.Label>End Date</Form.Label>
                                        <Form.Control type="date" value={endDate} max={maxDate} onChange={(e) => setEndDate(e.target.value)} />
                                    </Form.Group>
                                    <Button variant="primary" type="button" onClick={() => _getaudioDatabyDate(startDate, endDate)}>
                                        Diagonstic Info
                                    </Button>
                                </Form>
                            </div></>
            : ''
        }
        </div>

        {
            showLoader ?
            <div className="text-center mt-5 pt-5">
                <img src={loaderGif} alt="loading" height="150px" width="150px" />
            </div>
            : ""
        }
        
        {
            tableView ? 
            <div className="Tableview mt-5">
               <h4 className="my-5" onClick={() => goBack()}> <AiOutlineArrowLeft/> <span>Back</span></h4>
             <Table bordered responsive className="text-nowrap">
                    <thead className="bg-sky-lighter">
                        <tr>
                            <th>Client Id</th>
                            <th>Email id</th>
                            <th>Pid</th>
                            <th>Last Diagnostic Date</th>
                            <th>Private Report Url</th>
                            <th>Shared Report Url</th>
                            <th>Diagnostic attempt Url</th>
                        </tr>
                    </thead>
                    <tbody>
                    {audioData.length > 0 && audioData.map((value:any, key:any) => {
                        return(
                            <tr key={key}>
                                <td>{value.clientId}</td>
                                <td>{value.clientEmailId}</td>
                                <td>{value.pid}</td>
                                <td>{value.lastDiagnosticDate}</td>
                                <td><a href={value.privateReportUrl} target="_blank">Private link</a></td>
                                <td><a href={value.sharedReportUrl} target="_blank">Shared link</a></td>
                                <td> {value.audioProctoringDiagnosticInfos.length > 0 && value.audioProctoringDiagnosticInfos.map((data:any, id:any) => {
                                 return(  <span id={id}>
                                         <a href={data.link} target="_blank">{data.message}</a> &nbsp; &nbsp;
                                    </span>)
                                })}</td>
                            </tr>
                        )
                    })
                    }
                    </tbody>
        </Table>
        {
                        noData ? 
                        <div className="text-center">No Data Found</div> : ""
         }
        <div className='pagination-action mt-5 text-center'>
            {pagePrevious ? <button className='btn border' onClick={() => { _PreviousAudioData(acitivePaginationIndex) }}> <AiOutlineDoubleLeft/> Prev</button>: ""}
            {(pagePrevious || pageNext) ? <span className='px-3'>{acitivePaginationIndex}</span> : ""}
            {pageNext ? <button className='btn border' onClick={() => { _NextAudioData(acitivePaginationIndex) }}> Next <AiOutlineDoubleRight/> </button>: ""}
        </div>
            </div>
            : ''
        }
        
        </>
    )
}

export default AudioInfoComponent
