import React, { useState, useEffect } from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import { AiOutlineInfoCircle } from "react-icons/ai";
import { FaArrowDown, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import loaderGif from "../../images/gif/loader.gif";

import { mpaasHttpAction } from '../../utils/MpaasHttpActionHandler';
import MpaasAdminPanelApi from '../../utils/MpaasAdminPanelApi';
import dataStorage from '../../dataStorage/dataStorage';
import { DetailedAccountUsageSummaryModels, UserMetaData } from "../../models/DataModels";
import Logger from '../../utils/Logger';
import { useInitDataContext } from "../../utils/InitDataContextProvider";

const DetailedAccountUsageSummary = (props: any) => {
    const [initAppData] = useInitDataContext();
    const [tableView, setTableView] = useState(<></>);
    const [modalParams, setModalParams] = useState({} as any);

    const initData: any = props.initData.data;
    const viewLmit: number = 30;

    let acitivePaginationIndex: number = 1;
    let totalPaginationIndex: number;

    const handleDownloadError = (error: any) => {
        mpaasHttpAction.error(error);
        setModalParams({ ...modalParams, message: <span className="text-danger">{error.message}</span> });
    }

    const handleDownloadSuccess = (msg: string, resp: any) => {
        Logger.info(msg, resp);
        setModalParams({
            ...modalParams, message: (
                <>
                    <span className="d-inline-block align-top"><FaArrowDown /></span>
                    <span className="d-inline-block ml-2" style={{ width: "calc(100% - 25px)" }}>
                        <h4> Download Data</h4>
                        <p>The details requested will be sent to you on your available E-mail ID <br /><strong> {initAppData.email}</strong></p>
                        <p className="text-muted small mb-0"><AiOutlineInfoCircle /> The Download Link will be active for 24 hours only.</p>
                    </span>
                </>
            )
        });
    }

    const downloadDetailedUsageSummary = () => {
        setModalParams({
            "message": (<div className="text-center">
                <img src={loaderGif} alt="loading" height="100px" width="100px" />
            </div>)
        });

        MpaasAdminPanelApi.downloadDetailedAccountUsageSummary({ clientId: initData.mpaasClientId }).then((response: any) => {
            handleDownloadSuccess("Successfully sent email for download detailed account usage summary.", response);
        }, (error) => {
            handleDownloadError(error);
        });
    }

    const _renderTable = () => {
        let detailedUsageSummaryData: DetailedAccountUsageSummaryModels = dataStorage.getDetailedUsageSummaryData();
        let total: any = detailedUsageSummaryData?.totalCount
        totalPaginationIndex = Math.ceil(total / viewLmit);
        Logger.info("Detailed Account Usage Summary Table:", detailedUsageSummaryData);

        return (
            <>
                {/* <Button variant="link" className="float-right py-0" onClick={downloadDetailedUsageSummary}><FaArrowDown /> Download All</Button> */}
                <p className="text-quill-lightest ml-3">Showing {detailedUsageSummaryData.userMetaData?.length} Results</p>
                <Table bordered responsive className="text-nowrap">
                    <thead className="bg-sky-lighter">
                        <tr>
                            <th>Group Name</th>
                            <th>Unique ID</th>
                            <th>Proctoring - Screen Capture</th>
                            <th>Proctoring - Authorisation</th>
                            <th>Proctoring - Full Screen Recording</th>
                            <th>Proctoring Duration</th>
                        </tr>
                    </thead>
                    <tbody>
                        {detailedUsageSummaryData.userMetaData && detailedUsageSummaryData.userMetaData.length > 0 && detailedUsageSummaryData.userMetaData?.map((value: any, key: any) => {
                            return (
                                <tr key={key}>
                                    <td>{value.groupName}</td>
                                    <td>{value.emailId}</td>
                                    <td className="text-center"><span className={"dot bg-" + (value.screenCapture ? "success" : "danger")}></span> {value.screenCapture ? "True" : "False"}</td>
                                    <td className="text-center"><span className={"dot bg-" + (value.authorization ? "success" : "danger")}></span> {value.authorization ? "True" : "False"}</td>
                                    <td className="text-center"><span className={"dot bg-" + (value.fullScreenRecording ? "success" : "danger")}></span> {value.fullScreenRecording ? "True" : "False"}</td>
                                    <td>{value.proctoringDuration}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                <div className="text-center">
                    <Button variant="link" disabled={acitivePaginationIndex === 1} onClick={() => { paginationView(false, detailedUsageSummaryData.userMetaData) }}><FaChevronLeft className="align-text-bottom" /> Prev</Button>
                    <Button variant="link" disabled={acitivePaginationIndex === totalPaginationIndex} onClick={() => { paginationView(true, detailedUsageSummaryData.userMetaData) }}>Next <FaChevronRight className="align-text-bottom" /></Button>
                </div>
            </>
        )
    }

    const _fetchDataFromServer = (reqParams: object) => {
        dataStorage.setDetailedUsageSummaryData(null);
        setTableView(mpaasHttpAction.loading());

        MpaasAdminPanelApi.fetchDetailedUsageSummary(reqParams).then((response: any) => {
            dataStorage.setDetailedUsageSummaryData(response);
            setTableView(_renderTable);
        }, (error) => {
            setTableView(mpaasHttpAction.error(error));
        });
    }

    const paginationView = (isNext: boolean, userMetaDataList: UserMetaData[] | undefined) => {
        if (!userMetaDataList || userMetaDataList.length < 1) {
            Logger.error("Invalid params for pagination view", null);
            return;
        }

        let reqParams: any = {
            "mpaasClientId": initData.mpaasClientId,
            "upperBoundCandidateId": Math.max(...userMetaDataList.map(key => key.proctoringInstanceId)),
            "lowerBoundCandidateId": Math.min(...userMetaDataList.map(key => key.proctoringInstanceId)),
            "limit": viewLmit
        };

        if (isNext) {
            ++acitivePaginationIndex;
            reqParams.isNext = true;
        } else {
            --acitivePaginationIndex;
            reqParams.isNext = false;
        }

        _fetchDataFromServer(reqParams);
    }

    useEffect(() => {
        let reqParams = {
            "mpaasClientId": initData.mpaasClientId,
            "upperBoundCandidateId": null,
            "lowerBoundCandidateId": null,
            "isNext": null,
            "limit": viewLmit
        }

        _fetchDataFromServer(reqParams);

        return () => {
            dataStorage.setDetailedUsageSummaryData(null);
        }
    }, []);

    return (
        <>
            {tableView}
            <Modal show={Object.keys(modalParams).length > 0} onHide={() => void 0} animation={false} centered>
                <Modal.Body>{modalParams.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => { setModalParams({}) }}>Close</Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default DetailedAccountUsageSummary