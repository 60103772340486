import { InitData } from "../models/initData";

const userDetails: any = localStorage.getItem('userDetails');
let initData: InitData = JSON.parse(userDetails);

const initDataPacketStore = {
    setInitAppData: (data: InitData) => {
        initData = data;
    },
    getInitAppData: () => {
        return initData;
    }
}

export { initDataPacketStore };