import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import { FaRegUserCircle, FaArrowLeft, FaRegEye, FaBars,FaMicrophoneAlt } from "react-icons/fa";
import { BiCctv, BiRefresh } from "react-icons/bi";
import { VscKey } from "react-icons/vsc";
import { MdSettings } from "react-icons/md";

interface MyProps { }

interface MyState {
    menuCollapsed: Boolean
}

class SideNavBarComponent extends Component<MyProps, MyState> {
    constructor(props: MyProps) {
        super(props);

        this.state = {
            menuCollapsed: false
        };
    }

    toggleElemClassname = () => {
        const sidebarClassList = document.getElementsByClassName('customSideNavbar')[0].classList;
        const componentParentClassList = document.getElementsByClassName('_contentContainer')[0].classList;

        if (this.state.menuCollapsed) {
            sidebarClassList.add("slim-sidenavbar");
            componentParentClassList.add("sidenavbar-collapse");
        } else {
            sidebarClassList.remove("slim-sidenavbar");
            componentParentClassList.remove("sidenavbar-collapse");
        }
    }

    toggleSidenav = () => {
        this.setState({
            menuCollapsed: !this.state.menuCollapsed
        }, this.toggleElemClassname);
    }

    render() {
        return (
            <Navbar className="px-0">
                <Nav className="flex-column w-100">
                    <div className="nav-link pb-4 c-pointer" onClick={this.toggleSidenav}>
                        {this.state.menuCollapsed ? <FaBars/> : <FaArrowLeft />} <span className={this.state.menuCollapsed ? "d-none" : "px-2"}>Collapse this Panel</span>
                    </div>
                    <NavLink className="nav-link" activeClassName="active" to="/accountDetails">
                        <FaRegUserCircle /> <span className={this.state.menuCollapsed ? "d-none" : "px-2"}>Account Details</span>
                    </NavLink>
                    <NavLink className="nav-link" activeClassName="active" to="/monitorClientsActivity">
                        <BiCctv /> <span className={this.state.menuCollapsed ? "d-none" : "px-2"}>Monitor Client’s Activity</span>
                    </NavLink>
                    <NavLink className="nav-link" activeClassName="active" to="/mpaasLtiKeyHandling">
                        <VscKey /> <span className={this.state.menuCollapsed ? "d-none" : "px-2"}>MPaaS/LTI Keys Handling</span>
                    </NavLink>
                    <NavLink className="nav-link" activeClassName="active" to="/viewCandidateDetails">
                        <FaRegEye /> <span className={this.state.menuCollapsed ? "d-none" : "px-2"}>View Candidate Details</span>
                    </NavLink>
                    <NavLink className="nav-link" activeClassName="active" to="/viewRunningTests">
                        <BiRefresh /> <span className={this.state.menuCollapsed ? "d-none" : "px-2"}>View Running Tests</span>
                    </NavLink>
                    <NavLink className="nav-link" activeClassName="active" to="/audioProctoringInfo">
                        <FaMicrophoneAlt /> <span className={this.state.menuCollapsed ? "d-none" : "px-2"}>Audio Proctoring Info</span>
                    </NavLink>
                    <NavLink className="nav-link" activeClassName="active" to="/viewCandidateProctoringStatistics">
                        <FaRegEye /> <span className={this.state.menuCollapsed ? "d-none" : "px-2"}>View Candidate Proctoring Statistics</span>
                    </NavLink>
                    <NavLink className="nav-link" activeClassName="active" to="/settings">
                        <MdSettings /> <span className={this.state.menuCollapsed ? "d-none" : "px-2"}>Settings</span>
                    </NavLink>
                </Nav>
            </Navbar>
        )
    }
}

export default SideNavBarComponent
