const Logger = {
    info: (msg: string, data: any) => {
        data ? console.log(msg, data) : console.log(msg);
    },
    warn: (msg: string, data: any) => {
        data ? console.warn(msg, data) : console.warn(msg);
    },
    error: (msg: string, data: any) => {
        data ? console.error(msg, data) : console.error(msg);
    }
}

export default Logger;