import React, { Component } from 'react'

class ViewRunningTestsComponent extends Component {
    render() {
        return (
            <h4 className="pt-5 text-center">
                View Running Tests is coming soon...
            </h4>
        )
    }
}

export default ViewRunningTestsComponent
