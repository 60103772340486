import React from 'react';
import logger from "./Logger";
import loaderGif from "../images/gif/loader.gif";
import { Row, Col, Button } from "react-bootstrap";
import noResultFound from '../images/no_result_found.svg';
import errorImage from '../images/error_image.svg';
import { HttpErrorDataModel } from '../models/DataModels';

const loading = () => {
    return (
        <div className="text-center mt-5">
            <img src={loaderGif} alt="loading" height="150px" width="150px" />
        </div>
    )
}

const noRecordFound = () => {
    return (
        <Row className="mt-5 pt-5">
            <Col lg={5}>
                <img src={noResultFound} className="float-lg-right" alt="no result found" />
            </Col>
            <Col lg={7}>
                <div className="d-flex flex-column h-100 justify-content-center">
                    <h5>No Results Found</h5>
                    <p className="m-0">Try again with different parameters.</p>
                </div>
            </Col>
        </Row>
    )
}

const unableToProcessRequest = () => {
    return (
        <Row className="mt-5 pt-5">
            <Col lg={6}>
                <img src={errorImage} className="float-lg-right" height="250px" width="auto" alt="error" />
            </Col>
            <Col lg={6}>
                <div className="d-flex flex-column h-100 justify-content-center ml-4">
                    <h3 className="text-quill">Something went wrong...</h3>
                    <p className="m-0 text-quill-lightest">We can’t find the page you are looking for.<br /> You can refresh the page.</p>
                    <Button variant="primary" className="mt-3 w-25" onClick={() => { window.location.reload(); }}>Refresh Page</Button>
                </div>
            </Col>
        </Row>
    )
}

const invalidSession = () => {
    localStorage.removeItem("userDetails");
    window.location.href="/login";

    return (<></>)
}

const fetchDataActions = {
    LOADING: "LOADING",
    SUCCESS: "SUCCESS",
    ERROR: "ERROR"
}

const mpaasHttpAction = {
    loading: () => {
        return loading;
    },
    error: (data: any) => {
        let msg = data.message || "Server error";
        let errorData = data.error;

        logger.error(msg, errorData);

        if (!errorData || !errorData.error || !errorData.error.errorCode) {
            return unableToProcessRequest;
        }

        switch (errorData.error.errorCode) {
            case 'E000':
            case 'E002':
                return noRecordFound;

            case 'E006':
                if (errorData.showAlert) {
                    alert(msg);
                    return <></>;
                }

                return unableToProcessRequest

            case 'E022':
                return invalidSession;

            default:
                return unableToProcessRequest;
        }
    },
    makeErrorData: (msg: string, data: any): HttpErrorDataModel => {
        return {
            message: msg,
            error: data
        }
    }
}

export { mpaasHttpAction, fetchDataActions };
