import React, { useState, useEffect } from 'react';
import { Card, Button, Row, Col, Table, Modal } from 'react-bootstrap';
import { AiOutlineHome, AiOutlineInfoCircle } from "react-icons/ai";
import { GrFormClose } from "react-icons/gr";
import { FaArrowDown, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { HiArrowLeft } from "react-icons/hi";
import loaderGif from "../../images/gif/loader.gif";

import Validator from "../../utils/Validator";
import Logger from "../../utils/Logger";
import MpaasAdminPanelApi from '../../utils/MpaasAdminPanelApi';
import { mpaasHttpAction } from "../../utils/MpaasHttpActionHandler";
import dataStorage from '../../dataStorage/dataStorage';
import { AccountUsageSummary, ZohoAccountDetails } from "../../models/DataModels";
import DetailedAccountUsageSummary from '../accountDetails/DetailedAccountUsageSummary';
import { useInitDataContext } from "../../utils/InitDataContextProvider";

var emailList: string[] = [];
let acitivePaginationIndex: number = 1;
let totalPaginationIndex: number = 1;
let isViewAllDownload = false;

const MonitorClientsActivityComponent: React.FC = () => {
    const [initAppData] = useInitDataContext();
    const [inputEmail, setInputEmail] = useState('');
    const [inputEmailList, setInputEmailList] = useState<string[]>([]);
    const [emailInvalidErrorMsg, setEmailInvalidErrorMsg] = useState('');
    const [monitorActivityDetailsTableView, setMonitorActivityDetailsTableView] = useState(<></>);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [detailedAccUsageSumInitData, setDetailedAccUsageSumInitData] = useState({ isOpen: false, data: {} as any });
    const [showPagination, setShowPagination] = useState(false);
    const [modalParams, setModalParams] = useState({} as any);
    const tablePageSize: number = 30;

    let monitorAccountDetails: (AccountUsageSummary & ZohoAccountDetails)[] = [];

    const _validateAndAddEmail = (isSearchBtnClick: boolean) => {
        setEmailInvalidErrorMsg('');
        let emailArr = inputEmail.split(",");
        let _tempArr: string[] = []
        let isValidate = true;

        for (let i = 0; i < emailArr.length; i++) {
            const emailId = emailArr[i];
            let emailValidationData = Validator.validateEmail(emailId);

            if (isSearchBtnClick && emailId == '') {
                return isValidate;
            }

            if (!emailValidationData.isValid) {
                setEmailInvalidErrorMsg(emailValidationData.message);
                isValidate = false;
                return isValidate;
            }

            if (emailList.includes(emailId) || _tempArr.includes(emailId)) {
                setEmailInvalidErrorMsg("Duplicate email id.");
                isValidate = false;
                return isValidate;
            }

            _tempArr.push(emailId);
        }

        emailList = emailList.concat(emailArr);
        setInputEmailList(inputEmailList.concat(emailArr));
        setInputEmail('');

        return isValidate;
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key !== 'Enter') { return; }

        event.preventDefault();
        _validateAndAddEmail(false);
    }

    const _renderTable = () => {
        return (
            <>
                <hr />
                <h5>
                    Available Account Details
                    {/* <Button variant="link" className="float-right" onClick={downloadUsageSummary}><FaArrowDown /> Download All</Button> */}
                </h5>
                <p className="text-quill-lightest">Showing {monitorAccountDetails.length} Results</p>
                <Table bordered responsive className="text-nowrap">
                    <thead className="bg-sky-lighter">
                        <tr>
                            <th>Client E-mail ID</th>
                            <th>Zoho Id</th>
                            <th>Zoho Account Name</th>
                            <th>Last Session Date</th>
                            <th>Groups Created</th>
                            <th>Proctoring Duration (Hrs)</th>
                            {/* <th>Candidate video Rec. duration (Hrs)</th> */}
                            <th>Unique Reports generated</th>
                            <th>Avg. Proctoring Duration Per Report</th>
                        </tr>
                    </thead>
                    <tbody>
                        {monitorAccountDetails.length > 0 && monitorAccountDetails.map((value, key) => {
                            return (
                                <tr key={key}>
                                    <td>
                                        <span className="d-inline-block align-middle text-truncate" style={{ width: "200px" }} title={value.clientEmail}>{value.clientEmail}</span>
                                        <Button variant="outline-primary" size="sm" onClick={() => { setDetailedAccUsageSumInitData({ isOpen: true, data: { mpaasClientId: value.mpaasClientId, emailId: value.clientEmail } }) }}>View Details</Button>
                                    </td>
                                    <td>{value.zohoId}</td>
                                    <td>{value.zohoAccountName}</td>
                                    <td>{value.lastLoginDate}</td>
                                    <td>{value.groupsCount}</td>
                                    <td>{value.proctoringDuration}</td>
                                    {/* <td>{value.videoRecordingDuration}</td> */}
                                    <td>{value.usersCount}</td>
                                    <td>{value.avgProctoringDuration}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </>
        )
    }

    const _prepareAndDisplayMonitorAccountDetailsData = () => {
        let accountUsageSummaryData: AccountUsageSummary[] = dataStorage.getAccountUsageSummaryData();
        let zohoAccountDetails: ZohoAccountDetails[] = dataStorage.getZohoAccountDetails();

        for (let i = 0; i < accountUsageSummaryData.length; i++) {
            let usageSummary: AccountUsageSummary & ZohoAccountDetails;
            for (let j = 0; j < zohoAccountDetails.length; j++) {
                if (accountUsageSummaryData[i].clientEmail === zohoAccountDetails[j].emailId) {
                    usageSummary = { ...accountUsageSummaryData[i], ...zohoAccountDetails[j] }
                    monitorAccountDetails.push(usageSummary)
                }
            }
        }

        Logger.info("Monitor Account Details: ", monitorAccountDetails);
        setMonitorActivityDetailsTableView(_renderTable);
    }

    const _checkAndDisplayMonitorAccountDetails = () => {
        if (dataStorage.getAccountUsageSummaryData().length < 1 || dataStorage.getZohoAccountDetails().length < 1) {
            Logger.info("Fetching monitor account details data in progress.", null);
            return;
        }

        setIsButtonDisabled(false);
        _prepareAndDisplayMonitorAccountDetailsData();
    }

    const _resetData = () => {
        monitorAccountDetails = [];
        dataStorage.setAccountUsageSummaryData([]);
        dataStorage.setZohoAccountDetails([]);
    }

    const _fetchAllMonitorActivitiesFromServer = (params: object) => {
        MpaasAdminPanelApi.fetchAllAccountUsageSummary(params).then((response: any) => {
            Logger.info("All Monitor Account Details: ", response);
            monitorAccountDetails = response.usageSummaryList;
            totalPaginationIndex = Math.ceil(response.totalCount / tablePageSize);
            setMonitorActivityDetailsTableView(_renderTable);
            setShowPagination(true);
            setIsButtonDisabled(false);
        }, (error) => {
            setIsButtonDisabled(false);
            setMonitorActivityDetailsTableView(mpaasHttpAction.error(error));
        });
    }

    const _loadData = (reqParam: object) => {
        _resetData();
        setShowPagination(false);
        setIsButtonDisabled(true);
        setMonitorActivityDetailsTableView(mpaasHttpAction.loading());
        _fetchAllMonitorActivitiesFromServer(reqParam);
    }

    const handleDownloadError = (error: any) => {
        mpaasHttpAction.error(error);
        setModalParams({ ...modalParams, message: <span className="text-danger">{error.message}</span> });
    }

    const handleDownloadSuccess = (msg: string, resp: any) => {
        Logger.info(msg, resp);
        setModalParams({
            ...modalParams, message: (
                <>
                    <span className="d-inline-block align-top"><FaArrowDown /></span>
                    <span className="d-inline-block ml-2" style={{ width: "calc(100% - 25px)" }}>
                        <h4> Download Data</h4>
                        <p>The details requested will be sent to you on your available E-mail ID <br /><strong> {initAppData.email}</strong></p>
                        <p className="text-muted small mb-0"><AiOutlineInfoCircle /> The Download Link will be active for 24 hours only.</p>
                    </span>
                </>
            )
        });
    }

    const downloadUsageSummary = () => {
        setModalParams({
            "message": (<div className="text-center">
                <img src={loaderGif} alt="loading" height="100px" width="100px" />
            </div>)
        });

        MpaasAdminPanelApi.downloadAccountUsageSummary({ emailIds: emailList, isMonitorViewAllActivities: isViewAllDownload }).then((response: any) => {
            handleDownloadSuccess("Successfully sent email for download account usage summary.", response);
        }, (error) => {
            handleDownloadError(error);
        });
    }

    const paginationView = (isNext: boolean) => {
        if (isNext) {
            ++acitivePaginationIndex;
        } else {
            --acitivePaginationIndex;
        }

        let reqParam = { "pageSize": tablePageSize, "pageNo": acitivePaginationIndex };
        _loadData(reqParam);
    }

    const searchMonitorActivityDetails = () => {
        if (!_validateAndAddEmail(true)) {
            return;
        }

        if (emailList.length < 1) {
            setEmailInvalidErrorMsg("Please enter email id.");
            return;
        }

        isViewAllDownload = false;
        _resetData();
        setShowPagination(false);
        setIsButtonDisabled(true);
        setMonitorActivityDetailsTableView(mpaasHttpAction.loading());
        MpaasAdminPanelApi.fetchAccountUsageSummary({ emailIds: emailList }).then((response: any) => {
            dataStorage.setAccountUsageSummaryData(response);
            _checkAndDisplayMonitorAccountDetails();
        }, (error) => {
            setIsButtonDisabled(false);
            setMonitorActivityDetailsTableView(mpaasHttpAction.error(error));
        });

        MpaasAdminPanelApi.fetchZohoAccountDetails({ emailIds: emailList }).then((response: any) => {
            dataStorage.setZohoAccountDetails(response);
            _checkAndDisplayMonitorAccountDetails();
        }, (error) => {
            setIsButtonDisabled(false);
            setMonitorActivityDetailsTableView(mpaasHttpAction.error(error));
        });
    }

    const viewAllMonitorActivities = () => {
        let reqParam = { "pageSize": tablePageSize, "pageNo": acitivePaginationIndex };
        isViewAllDownload = true;
        _loadData(reqParam);
    }

    useEffect(() => {
        return () => {
            _resetData();
            emailList = [];
            acitivePaginationIndex = 1;
            totalPaginationIndex = 1;
            isViewAllDownload = false;
        }
    }, []);

    return (
        <>
            {!detailedAccUsageSumInitData.isOpen ?
                <div>
                    <AiOutlineHome /> <span className="small"> &gt; Monitor Client’s Activity</span>
                    <br /><br />
                    <h5>Monitor Client’s Details and Activity</h5>
                    <p className="text-muted small">You can provide Multiple Emails, separated by comma(,)</p>

                    <Row>
                        <Col xl={7} className="monitor-client-search-block">
                            <Card className="border-left-4 shadow-sm p-3">
                                <Card.Body className="text-muted font-weight-light p-0 mb-2">Please provide an Email ID</Card.Body>
                                <Row className="mb-2">
                                    <Col lg={7}>
                                        <input type="email" value={inputEmail} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setInputEmail(e.target.value) }} onKeyDown={handleKeyDown} className={"w-100 px-2 py-1 border rounded bg-sky-lighter " + (emailInvalidErrorMsg ? 'border-danger' : '')} placeholder="email@email.com" required />
                                    </Col>
                                    <Col lg={2}>
                                        <Button variant="primary" className="w-100 py-1" disabled={isButtonDisabled} onClick={searchMonitorActivityDetails}>Search</Button>
                                    </Col>
                                    <Col lg={3} className="border-left">
                                        <Button variant="outline-primary" className="py-1" disabled={isButtonDisabled} onClick={viewAllMonitorActivities}>View all Activities</Button>
                                    </Col>
                                </Row>
                                {emailInvalidErrorMsg && <div className="text-danger mb-2">{emailInvalidErrorMsg}</div>}
                                <div style={{ marginLeft: "-8px" }}>
                                    {inputEmailList.map((emailId, index) => {
                                        return (
                                            <span key={index} className={"d-inline-block px-2 py-1 small border border-radius-15 mb-2 ml-2 "}>{emailId} <GrFormClose className="c-pointer" onClick={() => { setInputEmailList(inputEmailList.filter(i => i !== emailId)); emailList.splice(emailList.indexOf(emailId), 1);}} /></span>
                                        )
                                    })}
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    {monitorActivityDetailsTableView}
                    {showPagination && <div className="text-center">
                        <Button variant="link" disabled={acitivePaginationIndex === 1} onClick={() => { paginationView(false) }}><FaChevronLeft className="align-text-bottom" /> Prev</Button>
                        <Button variant="link" disabled={acitivePaginationIndex === totalPaginationIndex} onClick={() => { paginationView(true) }}>Next <FaChevronRight className="align-text-bottom" /></Button>
                    </div>
                    }
                </div>
                :
                <>
                    <AiOutlineHome /> <span className="small"> &gt; <span className="text-muted">Monitor Client’s Activity</span> &gt; Client Detail</span>
                    <br /><br />
                    <h5>
                        <HiArrowLeft className="align-bottom c-pointer" onClick={() => { setDetailedAccUsageSumInitData({ isOpen: false, data: {} }) }} /> Mpaas Client ID: {detailedAccUsageSumInitData.data?.mpaasClientId}
                    </h5>
                    <DetailedAccountUsageSummary initData={detailedAccUsageSumInitData} setInitData={setDetailedAccUsageSumInitData} />
                </>
            }

            <Modal show={Object.keys(modalParams).length > 0} onHide={() => void 0} animation={false} centered>
                <Modal.Body>{modalParams.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => { setModalParams({}) }}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default MonitorClientsActivityComponent