import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import SidebarDocked from "react-sidebar";
import { useInitDataContext } from "../utils/InitDataContextProvider";
import HeaderComponent from "../components/header/HeaderComponent";
import SideNavBarComponent from "../components/sidenavbar/SideNavBarComponent";
import FooterComponent from "../components/footer/FooterComponent";
import { MpaasConfigService } from '../components/config/mpaas-web-config';
import { httpDataStatus } from './MpaasHttpClient';

const ProtectedRoute: React.FC<any> = ({ component: Component, ...props }) => {
    const [initAppData] = useInitDataContext();
    const sidebarStyle = { background: "#F9FAFB", position: "fixed", top: "62px", bottom: "60px" };

    const fetchConfigurations = () => {
        MpaasConfigService.getConfigurations().then((resp: any)=> {
            if(resp.status === httpDataStatus.SUCCESS) {
                console.log(resp);
            }   
        })
    }

    useEffect(() => {
        fetchConfigurations();
    }, []);

    return (
        <Route path={props.path} render={data => (!!initAppData ) ? (
            <div className="main-body h-100 w-100 position-absolute bg-dark">
                <HeaderComponent />

                <SidebarDocked sidebar={<SideNavBarComponent />} docked={true} transitions={true} shadow={false} sidebarClassName={"customSideNavbar border-right"} styles={{ sidebar: sidebarStyle }}>
                    <></>
                </SidebarDocked>

                <div className="_contentContainer content-container position-absolute bg-white default-outer-space p-4 overflow-auto">
                    <Component {...data} />
                </div>
                <FooterComponent />
            </div>
        ) : (
                <Redirect to={{ pathname: '/login' }} />
            )} >
        </Route>
    )
};

export default ProtectedRoute
